import styled, { css } from 'styled-components';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { LinksProps } from './index';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { above, below } from '../../../styles/tools/media';
import {
	smBodyBook,
	smBodyRegular,
	xxlTitleRegular,
} from '../../atoms/Typography/index.styled';

type Hero__ResponsiveContainerProps = {
	type?: string
};

type SvgContainerProps = {
	responsiveImage: boolean
	type?: string
};

type SvgContainerInner = {
	type?: string
};

const Hero = styled.section`
	position: relative;
	background-color: var(--primaryBackground);

	&::before {
		content: '';
		position: absolute;
		bottom: -16px;
		left: 50%;
		width: 0;
		height: 0;
		border: 0 solid transparent;
		border-left-width: 16px;
		border-right-width: 16px;
		border-top: 16px solid var(--primaryBackground);
		transform: translateX(-50%);
	}

	${above(
		breakpoints[600],
		() => css`
			&::before {
				bottom: -24px;
				border-left-width: 24px;
				border-right-width: 24px;
				border-top-width: 24px;
			}
		`
	)}
`;

const Hero__inner = styled(Grid)`
	padding-bottom: 48px;
`;

const Hero__innerCopy = styled(smBodyBook)`
	margin-bottom: ${gap[32]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[40]};
		`
	)}
`;

const Hero__ResponsiveContainer = styled.div<Hero__ResponsiveContainerProps>`
	grid-column: 1 / -1;
	position: relative;
	z-index: 1;
	margin-top: ${spacing[64]};

	${above(
		breakpoints[600],
		() => css`
			grid-column: 1 / span 4;
			align-self: center;
		`
	)}

${props =>
	props.type === 'column' &&
	css`
		${above(
			breakpoints[600],
			() => css`
				grid-column: 1 / span 12;
			`
		)}
	`}

	${above(
		breakpoints[960],
		() => css`
			grid-column: 1 / span 6;
			margin-top: 0;
		`
	)}

${props =>
	props.type === 'column' &&
	css`
		${above(
			breakpoints[960],
			() => css`
				grid-column: 4 / span 6;
				margin-top: ${spacing[64]};
			`
		)}
	`}
`;

const Hero__svgContainer = styled.div<SvgContainerProps>`
	grid-column: 1 / span 4;
	margin-top: 56px;
	padding: 0 24px;

	${props =>
		props.type === 'column' &&
		css`
			${below(
				breakpoints[600],
				() => css`
					padding: 0;
					margin-left: -16px;
					margin-right: -16px;
				`
			)}
		`}

	${props =>
		!props.responsiveImage &&
		css`
			${below(
				breakpoints[600],
				() => css`
					display: none;
				`
			)}
		`}

	${above(
		breakpoints[600],
		() => css`
			grid-column: 5 / span 4;
			align-self: center;
		`
	)}

${props =>
	props.type === 'column' &&
	css`
		${above(
			breakpoints[600],
			() => css`
				grid-column: 1 / span 12;
			`
		)}
	`}

	${above(
		breakpoints[960],
		() => css`
			grid-column: 7 / span 6;
			margin-top: 120px;
			margin-bottom: 80px;
		`
	)}

${props =>
	props.type === 'column' &&
	css`
		${above(
			breakpoints[960],
			() => css`
				grid-column: 1 / span 12;
				margin-top: 120px;
				margin-bottom: 80px;
			`
		)}
	`}
`;

const Hero__svgContainerInner = styled.div<SvgContainerInner>`
	max-width: ${props => (props.type === 'column' ? 'auto' : '450px')};
	display: block;
	margin: auto;
`;

// const Hero__imageContainerSimple = styled.div``;

const Hero__TitleSecondaryHero = styled(xxlTitleRegular)`
	color: var(-noir);
	margin-bottom: ${margins[24]};
`;

const Hero__TextContainer = styled.div`
	padding-top: 32px;
	padding-bottom: 32px;

	${above(
		breakpoints[960],
		() => css`
			padding-top: 7.5vw;
			padding-right: 20vw;
		`
	)}
`;

const Hero__BodyHeading = styled(smBodyRegular)`
	margin-top: ${gap[32]};
	margin-bottom: ${gap[16]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[24]};
		`
	)}
`;

const Hero__LinkList = styled.ul<LinksProps>`
	display: grid;
	grid-template-columns: auto;
	grid-gap: ${gap[16]};
	padding-left: 0;
	list-style-type: none;

	${props =>
		!props.linksStacked &&
		css`
			${above(
				breakpoints[600],
				() => css`
					grid-template-columns: auto auto;
				`
			)}
		`}

	${props =>
		!props.linksStacked &&
		props.type === 'column' &&
		css`
			${above(
				breakpoints[600],
				() => css`
					grid-template-columns: auto auto auto;
				`
			)}
		`}
`;

const Hero__LinkItem = styled.li``;

export default {
	Hero,
	Hero__inner,
	Hero__svgContainer,
	Hero__svgContainerInner,
	Hero__ResponsiveContainer,
	Hero__TitleSecondaryHero,
	Hero__TextContainer,
	Hero__innerCopy,
	Hero__BodyHeading,
	Hero__LinkList,
	Hero__LinkItem,
};
